<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Third Party Payments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-btn icon class="ml-10" right @click="getPayments">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-toolbar color="secondary" flat dense>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="options.startDate"
              label="Start Date"
              readonly
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.startDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="options.endDate"
              label="End Date"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.endDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
        <v-col cols="4">
          <v-autocomplete
            dense
            outlined
            multiple
            search-input=""
            v-model="options.Source"
            :items="Sources"
            item-text="text"
            item-value="value"
            label="Sources"
          ></v-autocomplete>
        </v-col>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :search="search"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="payments"
              dense
              class="elevation-1"
              :options.sync="options"
              :server-items-length="totalItems"
              :server-search="true"
              :sort-key.sync="options.sortKey"
              :sort-desc.sync="options.sortOrder"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 50, 200],
                showCurrentPage: true,
              }"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.Source="{ item }">
                <div v-if="item.Source ===  1">
                  KCB
                </div>
                <div v-if="item.Source ===  2">
                  Equity
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: "",
      payments: [],
      totalItems: 0,
      startDate: null,
      endDate: null,
      selectedItem: null,
      salesEmployees: [],
      options: {
        startDate: null,
        endDate: null,
        selectedItem: [],
        rowsPerPageOptions: [10, 20, 30],
        itemsPerPageOptions: [10, 20, 30],
        itemsPerPage: 50,
        page: 1,
        sortKey: "Balance",
        sortOrder: true,
        Source: [],
      },
      headers: [
        { text: "Transaction Id", value: "TransID" },
         { text: "Source", value: "Source" },
        { text: "Doc Num", value: "DocNum" },
        { text: "Name", value: "FirstName" },
        { text: "Amount", value: "TransAmount" },
       // { text: "Currency", value: "Currency" },
        ///{ text: "Phone.No", value: "MSISDN" },
        { text: "Description", value: "Dscription" },
        { text: "Transact Type", value: "TransactType" },
        { text: "Balance", value: "Balance" },
        { text: "Created at", value: "created_at" },
      ],
      Sources: [
        { text: "KCB Bank", value: 1 },
        { text: "Equity Bank", value: 2 },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getPayments();
        this.defaultDates();
      },
      deep: true,
    },

    search() {
      this.options.page = 1;
      if (this.search.length >= 3) {
        this.getPayments();
      }
      if (this.search.length == 0) {
        this.getPayments();
      }
    },
  },

  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        this.options.startDate = firstDayOfMonth.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const lastDayOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        );
        this.options.endDate = lastDayOfMonth.toISOString().substr(0, 10);
      }
    },
    getPayments() {
      const self = this;
      this.loading = true;
      let url = `/payments/incoming/third-party?paginate=1&page=${this.options.page}&per_page=${this.options.itemsPerPage}`;
      if (this.options.startDate) {
        url += `&startDateTime=${this.options.startDate}`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}endDateTime=${
          this.options.endDate
        }`;
      }
      if (this.search) {
        self.payments = [];
        url += `${url.includes("?") ? "&" : "?"}search=${this.search}`;
      }
      if (this.options.Source.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }Source=${this.options.Source.map(
          (p) => `${encodeURIComponent(p)}`
        ).join(",")}`;
      }

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.payments = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    updateRange() {
      if (
        this.options.startDate &&
        this.options.endDate &&
        this.options.startDate > this.options.endDate
      ) {
        this.$refs.snackbar.show(
          "Start date cannot be greater than End date",
          "red"
        );

        this.options.endDate = "";
      }
    },
  },

  mounted() {
    this.defaultDates();
    this.getPayments();
    setInterval(this.getPayments, 30000);
  },
};
</script>
